//#region extendValidationSettings.  Set defaults that can be overridden

function extendValidationSettings(settings) {
    var extended = $.extend({}, {
        ignore: '',
        errorClass: "invalid",
        validClass: "valid",
        errorElement: "li",
        errorPlacement: function (error, element) {
            var errorMessageBox = $('.errorMessageBox');

            errorMessageBox.show();
            errorMessageBox.find('.errorList').append(error);

            $(element).change(function () {
                if (errorMessageBox.find('li:visible').length == 0) {
                    errorMessageBox.hide();
                }
            });
        },
        highlight: function (element, errorClass, validClass) {
            if (element.type === "radio") {
                this.findByName(element.name).addClass(errorClass).removeClass(validClass).parent().addClass('state-error').removeClass('state-success');
            } else {
                $(element).addClass(errorClass).removeClass(validClass).parent().addClass('state-error').removeClass('state-success');
            }
        },
        unhighlight: function (element, errorClass, validClass) {
            if (element.type === "radio") {
                this.findByName(element.name).removeClass(errorClass).addClass(validClass).parent().removeClass('state-error');
            } else {
                $(element).removeClass(errorClass).addClass(validClass).parent().removeClass('state-error');
            }
        },
        submitHandler: function (form) {
            isDirtyOverridden = true;

            //if (fieldIdsToRemoveMask) {
            //    $.each(fieldIdsToRemoveMask, function (i, e) {
            //        $('#' + e).inputmask('remove');
            //    });
            //}

            form.submit();
        }
    }, settings);

    return extended;
}

//#endregion